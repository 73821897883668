import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const [totalSupply, setTotalSupply] = useState(0);
  const [mintAmount, setMintAmount] = useState(1);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState("");
  const saleStart = true;
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState(null);

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 0,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    SHOW_BACKGROUND: false,
  });

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const checkTotalSupply = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .totalSupply()
        .call()
        .then((receipt) => {
          setTotalSupply(receipt);
          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const claimNFTs = async () => {
    let cost = CONFIG.WEI_COST;
    let totalCostWei = String(cost * mintAmount);
    let balance = await blockchain.web3.eth.getBalance(blockchain.account);
    let balanceWei = Number(balance);
    if (balanceWei < totalCostWei) {
      alert("ウォレットの残高が不足しています。");
      return;
    }
    let method = await blockchain.smartContract.methods.publicMint(mintAmount);
    setFeedback(`しばらくそのままお待ちください...`);
    setClaimingNft(true);
    try {
      let receipt = await method.send({
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        maxPriorityFeePerGas: "40000000000",
      });
      console.log(receipt);
      setFeedback(`ミント成功!ありがとうございました!!`);
      setClaimingNft(false);
      checkTotalSupply();
      dispatch(fetchData(blockchain.account));
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } catch (err) {
      console.log(err);
      setFeedback("もう一度お試しください");
      setClaimingNft(false);
    }
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const openPopup = (contentType) => {
    setShowPopup(true);
    setPopupContent(contentType);
  };

  const closePopup = () => {
    setShowPopup(false);
    setPopupContent(null);
  };

  useEffect(() => {
    getData();
    getConfig();
    checkTotalSupply();
  }, []);

  useEffect(() => {
    getData();
    getConfig();
    checkTotalSupply();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: "0px" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        {/* ヘッダー部分 */}
        <s.TextDescription style={{ position: "fixed", width: "100%", fontSize: 26, textAlign: "left", backgroundColor: "rgba(0, 0, 0)" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <s.StyledLink style={{ color: "#fff", margin: "8px 0px 0px 14px" }} href="">
              <s.TextDescription style={{ fontSize: "20px", paddingBottom: "6px" }}>Covered Records</s.TextDescription>
            </s.StyledLink>
            <div style={{ display: "flex", alignItems: "center", marginRight: "16px" }}>
              <a href="https://twitter.com/KedamaNft" target="_blank" rel="noopener noreferrer" style={{ marginRight: "16px" }}>
                <img style={{ width: "18px", marginTop: "6px" }} src="/config/images/x.png" alt="X Icon" />
              </a>
              <a href="https://opensea.io/collection/covered-records-the-day-over-cooked-hokke-matic" target="_blank" rel="noopener noreferrer">
                <img style={{ width: "23px", marginTop: "11px" }} src="/config/images/os.png" alt="OpenSea Icon" />
              </a>
            </div>
          </div>
        </s.TextDescription>

        <s.Container flex={2} jc={"center"} ai={"center"} style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", paddingTop: "64px" }}>
          <img src="/config/images/jacket.png" alt="Jacket" />
          {!saleStart ? (
            <s.TextTitle>Coming Soon...</s.TextTitle>
          ) : (
            <>
              <s.TextTitle style={{ marginBottom: "6px" }}>- Now on sale -</s.TextTitle>
              {Number(totalSupply) >= CONFIG.MAX_SUPPLY ? (
                <s.TextTitle>Sold out! Thank you!!</s.TextTitle>
              ) : (
                <>
                  {blockchain.account === "" ||
                    blockchain.smartContract === null ? (
                    <s.Container ai={"center"} jc={"center"}>
                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <s.StyledRoundButton
                          style={{ lineHeight: 0.4 }}
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            decrementMintAmount();
                          }}
                        >
                          -
                        </s.StyledRoundButton>
                        <s.SpacerXSmall />
                        <s.TextTitle
                          style={{
                            paddingBottom: "2px",
                            fontSize: "26px",
                            textAlign: "center",

                          }}
                        >
                          {mintAmount}
                        </s.TextTitle>
                        <s.SpacerXSmall />
                        <s.StyledRoundButton
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            incrementMintAmount();
                          }}
                        >
                          +
                        </s.StyledRoundButton>
                      </s.Container>
                      <s.StyledButton
                        style={{ fontWeight: "bold" }}
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                        }}
                      >
                        POLで購入
                      </s.StyledButton>
                      <s.TextDescription style={{ fontSize: "12px", marginBottom: "8px" }}>MetaMaskでコネクトします</s.TextDescription>
                      <s.StyledButton
                        style={{ fontWeight: "bold" }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={() => {
                          window.location.href = `https://paypiement.xyz/ja/projects/019b906c-faf2-4f1d-9394-31a28957145f?quantity=${mintAmount}`;
                        }}
                      >
                        クレカで購入
                      </s.StyledButton>
                      <s.TextDescription style={{ fontSize: "11px" }}>※NFTを受け取るウォレットアドレスが必要です</s.TextDescription>
                      <s.TextDescription style={{ fontSize: "11px" }}>※システム利用料がかかります</s.TextDescription>
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <s.TextDescription style={{ fontSize: "11px" }}>{blockchain.errorMsg}</s.TextDescription>
                        </>
                      ) : null}
                    </s.Container>
                  ) : (
                    <>
                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <s.StyledButton
                          style={{ fontWeight: "bold" }}
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            claimNFTs();
                            getData();
                          }}
                        >
                          {claimingNft ? "Mint now" : "Mint"}
                        </s.StyledButton>
                      </s.Container>
                      <s.TextTitle style={{ fontSize: "13px" }}>
                        購入数 {mintAmount} = {mintAmount * CONFIG.DISPLAY_COST}POL
                      </s.TextTitle>
                      <s.TextDescription style={{ fontSize: "13px" }}>
                        Wallet address : {truncate(blockchain.account, 4)}{truncate(blockchain.account.slice(-4), 4)}
                      </s.TextDescription>
                      <s.TextDescription style={{ fontSize: "13px" }}>
                        {feedback}
                      </s.TextDescription>
                    </>
                  )}
                </>
              )}
            </>
          )}
          <div style={{ marginTop: "24px", color: "#fff", fontSize: "14px" }}>どんなジャケットが出るかお楽しみに！</div>
          {/* 特定商取引法とプライバシーポリシーのリンク */}
          <div style={{ margin: "48px 0px 24px", display: "flex", justifyContent: "center", color: "#fff", fontSize: "9px" }}>
            <div style={{ margin: "10px" }}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  openPopup("terms");
                }}
                style={{ textDecoration: "none", color: "#fff" }}
              >
                特定商取引法に基づく表記
              </a>
            </div>
            <div style={{ margin: "10px" }}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  openPopup("privacy");
                }}
                style={{ textDecoration: "none", color: "#fff" }}
              >
                個人情報の取扱いについて
              </a>
            </div>
          </div>

          {/* ポップアップウィンドウ */}
          {showPopup && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                backgroundColor: "rgba(0,0,0,0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 9999,
              }}
              onClick={closePopup}
            >
              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "20px",
                  borderRadius: "8px",
                  maxWidth: "90vw",
                  maxHeight: "80vh",
                  overflowY: "auto",
                  cursor: "auto",
                }}
                onClick={(e) => e.stopPropagation()}
              >
                {popupContent === "terms" ? (
                  <iframe src="/config/images/2.pdf" width="100%" height="100%" title="Terms of Service" />
                ) : (
                  <iframe src="/config/images/1.pdf" width="100%" height="100%" title="Privacy Policy" />
                )}
              </div>
            </div>
          )}
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
